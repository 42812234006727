import React from "react"
import checkMark from "../../assets/icons/noun-check-mark.svg"
import cyberSecurity1 from "../../assets/icons/noun_cyber security_4310034.svg"
import fc from "../../assets/icons/noun_Accounting and Finance_1926047.svg"
import benchMarket from "../../assets/icons/noun_capital market_3346079.svg"
import Service from "../../components/Shared/Service"

import { graphql } from "gatsby"

const seoTitle = "Business Process "
const mainHeading = "Business Process"
const keyword =
  " Business Process, Finance and Accounting, Cyber Security, Functional expertise, Data and Analytics, Risk and Compliance, Banker Productivity, Capital Markets, BPO"
const seoDescription =
  ": Atyeti provides BPM technology and process capability to modernize operating models. We enable the power of digital to help run your business."
const mainPara = `Atyeti provides  BPM technology and process capability to modernize operating models. We enable the power of digital to help run your business.`

const serviceIcons = [
  { id: 1, name: "Finance and Accounting", icon: checkMark },
  { id: 2, name: "Cyber Security", icon: checkMark },
  { id: 3, name: " Functional expertise", icon: checkMark },
  { id: 4, name: " Data and Analytics", icon: checkMark },
  { id: 5, name: "Risk and Compliance", icon: checkMark },
  { id: 6, name: "Banker Productivity", icon: checkMark },
  { id: 7, name: "Capital Markets", icon: checkMark },
  { id: 8, name: "BPO", icon: checkMark },
]

const section2Heading = `Sampling of our Services`
const section2Para = `Leverage the right blend of functionality, data integration, and supporting technologies to augment your digital transformation to help reduce cost and increase operational efficiency.`

const cardItems = [
  {
    id: 1,
    icon: fc,
    heading: `Functional Expertise`,
    listItems: (
      <>
        <li>
          Provided the Front Office team with an architectural solution to
          provide all downstream applications with Front office data .
        </li>
        <li>
          Atyeti associates experienced in Cash Equities for Front office
          sourcing (Single source of Data, FO-BO) helped build a normalized and
          repeatable data model.
        </li>
        <li>
          Responsible for reconciliation of reporting data and reports submitted
          to regulators in the G-20 jurisdiction
        </li>
        <li>
          Atyeti team supports Banking entities with G20 and FINMA regulations -
          mandating Listed and OTC derivatives
          trades/valuations/positions/collateral to be reported to external
          repositories. within the project.{" "}
        </li>
      </>
    ),
  },
  
  {
    id: 2,
    icon: benchMarket,
    heading: `Benchmarking and KYC Analysts`,
    listItems: (
      <>
        <li>
          A process used by Fund Managers to compare the performance of their
          funds against an appropriate index.
        </li>
        <li>
          The Atyeti team executes the Business Process creating the feed
          specifications.
        </li>
        <li>
          Complete KYC on boarding for new and pre-exiting customer by reviewing
          client documentation, vendor databases, and data etc
        </li>
        <li>
          Performed data quality review of KYCs for completeness, including
          ensuring all appropriate information is contained in the KYC to meet
          all regulatory, legal, bank and audit requirements
          {/* for calculation of the Index level return and analytics. */}
        </li>
      </>
    ),
  },
  {
    id: 3,
    icon: cyberSecurity1,
    heading: `Cyber Security- SOC`,
    listItems: (
      <>
        <li>
          Staffed up a (SOC) security team responsible for monitoring and
          analyzing our clients security.
        </li>
        <li>
          The team’s goal is to detect, analyze, and respond to cybersecurity
          incidents using a combination of technology solutions and a strong set
          of processes.
        </li>
        <li>
          Looking for anomalous activity that could be indicative of a security
          incident or compromise.
        </li>
      </>
    ),
  },
]

const ServiceDetails = ({ data }) => (
  <Service
    seoTitle={seoTitle}
    mainHeading={mainHeading}
    mainPara={mainPara}
    serviceIcons={serviceIcons}
    // section2Heading={section2Heading}
    cardItems={cardItems}
    section2Para={section2Para}
    caseItems={data}
    seoDescription={seoDescription}
    keyword={keyword}
    className="business-process"
  />
)
export const query = graphql`
  query BusinessQuery {
    casestudyJson(mainheading: { eq: "business-process" }) {
      data {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        para
        slug
        subheading
      }
      bannerimage {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
export default ServiceDetails
